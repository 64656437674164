// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".wrapper {\n  min-height: 680px;\n  max-height: 680px;\n}\n.highcharts-color-0 {\n  fill: rgba(var(--bs-success-rgb));\n}\n.highcharts-color-0 .highcharts-tooltip-box {\n  stroke: rgba(var(--bs-success-rgb));\n}\n.highcharts-color-1 {\n  fill: rgba(var(--bs-warning-rgb));\n}\n.highcharts-color-1 .highcharts-tooltip-box {\n  stroke: rgba(var(--bs-warning-rgb));\n}\n.highcharts-color-2 {\n  fill: rgba(var(--bs-danger-rgb));\n}\n.highcharts-color-2 .highcharts-tooltip-box {\n  stroke: rgba(var(--bs-danger-rgb));\n}\n.highcharts-color-3 {\n  fill: rgba(var(--bs-info-rgb));\n}\n.highcharts-color-3 .highcharts-tooltip-box {\n  stroke: rgba(var(--bs-info-rgb));\n}\n.highcharts-tt-color-0 {\n  color: rgba(var(--bs-success-rgb));\n}\n.highcharts-tt-color-1 {\n  color: rgba(var(--bs-warning-rgb));\n}\n.highcharts-tt-color-2 {\n  color: rgba(var(--bs-danger-rgb));\n}\n.highcharts-tt-color-3 {\n  color: rgba(var(--bs-info-rgb));\n}\n.highcharts-tooltip {\n  z-index: 5;\n}\n.loading-skeleton {\n  background-color: var(--bs-light);\n  height: 17px;\n  opacity: 0.1;\n  width: 100%;\n  animation: fading 1.5s infinite;\n}\n@keyframes _192iIpnE6VCrW2kLTWA6sw {\n  0% {\n    opacity: 0.2;\n  }\n  50% {\n    opacity: 0.4;\n  }\n  100% {\n    opacity: 0.2;\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"fading": "_192iIpnE6VCrW2kLTWA6sw"
};
module.exports = exports;
