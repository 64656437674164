import i18next from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      ttdRti: {
        viewSelector: {
          technicalIssue: {
            title: "Technical issue",
            message: "A problem occurred while loading this content.",
          },
        },
        noData: "No data to display",
        retryBtn: "Retry",
      },
    },
  },
  fr: {
    translation: {
      ttdRti: {
        viewSelector: {
          technicalIssue: {
            title: "Problème technique",
            message:
              "Un problème est survenu pendant le chargement de ce contenu.",
          },
        },
        noData: "Aucune donnée à afficher",
        retryBtn: "Réessayer",
      },
    },
  },
};

i18next.use(initReactI18next).init({
  resources,
  lng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18next;
