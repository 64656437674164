import React, { useState } from "react";

type Props = {
  statuses: string[];
  defaultStatus: string;
  onStatusChange: (status: string) => void;
};

const StatusNavTemplate: React.FC<Props> = ({
  statuses,
  defaultStatus,
  onStatusChange,
}: Props) => {
  const [activeTabState, setActiveTabState] = useState(defaultStatus);
  const handleTabChange = (status: string): void => {
    setActiveTabState(status);
    onStatusChange(status);
  };
  if (activeTabState !== defaultStatus) {
    setActiveTabState(defaultStatus);
  }
  return (
    <ul className="nav nav-xl">
      {statuses.map(status => (
        <li
          className="nav-item"
          style={{ cursor: "pointer" }}
          key={status}
          onClick={() => handleTabChange(status)}
        >
          <span
            className={`nav-link ${
              activeTabState === status ? "active" : ""
            } pt-0`}
          >
            {status}
          </span>
        </li>
      ))}
    </ul>
  );
};

export default StatusNavTemplate;
