import React from "react";

const Loading = (): JSX.Element => {
  return (
    <div className="d-flex">
      <div className="flex-grow-1 me-24px" style={{ minWidth: "187px" }}>
        <div className="text-truncate py-3">
          <div className="loading-skeleton"></div>
        </div>
        <div className="text-truncate py-3">
          <div className="loading-skeleton"></div>
        </div>
        <div className="text-truncate py-3">
          <div className="loading-skeleton"></div>
        </div>
      </div>
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ minWidth: "175px", height: "175px" }}
      >
        <div
          className="loading-skeleton block rounded-circle"
          style={{ width: "155px", height: "155px" }}
        ></div>
      </div>
    </div>
  );
};

export default Loading;
