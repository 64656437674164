import React from "react";

type Props = {
  kpiData: {
    name: string;
    y: number;
  }[];
  navigationRequest?: (url: string) => void;
};

const KpiListTemplate: React.FC<Props> = ({
  kpiData,
  navigationRequest,
}: Props) => {
  const bgColors = ["bg-success", "bg-warning", "bg-danger", "bg-info"];
  return (
    <div className="flex-grow-1 me-24px" style={{ minWidth: "187px" }}>
      {kpiData?.map((k, index) => (
        <div
          key={k.name}
          className={`d-flex flex-column card card-filling px-8px text-truncate flex-grow-1 py-3 pe-3 ${
            index < kpiData.length - 1 ? "border-bottom border-opacity-40" : ""
          }`}
          style={{ cursor: "pointer" }}
          onClick={() =>
            k.y !== 0 && navigationRequest && navigationRequest(k.name)
          }
        >
          <div className="d-flex flex-column text-truncate flex-grow-1">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center text-truncate">
                <div
                  className={`rounded-circle ${bgColors[index]} me-16px`}
                  style={{ height: "10px", width: "10px" }}
                ></div>

                <div className="text-truncate pe-3">
                  <span>{k.name}</span>
                </div>
              </div>

              <div>{k.y}</div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default KpiListTemplate;
