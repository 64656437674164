import React, { useEffect, useRef, useState } from "react";
import {
  widgetize,
  WithCss,
  WidgetPropsMapping,
  useWidgetBusData,
} from "@sg-widgets/react-core";
import * as widgetStyles from "./ttd-real-time-indicators.less";
import Highcharts, { SeriesVariablepieOptions } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import StatusNavTemplate from "./components/status-nav-template";
import { getOptions } from "./highcharts.options";
import { Rti, RtiData } from "../models/rti.model";
import KpiListTemplate from "./components/kpi-list-template";
import i18n from "./i18n";
import Loading from "./components/loading-template";
import { fadingKeyframes } from "../utils";
import { NoDataFiller } from "@sgss-ttd-widgets/components";
import { useTranslation } from "react-i18next";

interface Props {
  rtiData: Rti[];
  isLoading: boolean;
  isError: boolean;
  navigationRequest: (url: { status: string; label: string }) => void;
  retryClick: () => void;
}

const RTI: React.FC<Props> = ({
  rtiData,
  isLoading,
  isError,
  navigationRequest,
  retryClick,
}) => {
  const chartComponentRef = useRef<HighchartsReact>(null);
  const sVGElements: Highcharts.SVGElement[] = [];
  const currentLanguage: string = useWidgetBusData("global.language")!;
  const { t: translate } = useTranslation();
  const [status, setStatus] = useState<string>();
  const latestStatus = useRef(status);
  const [options, setOptions] = useState(
    getOptions(sVGElements, currentLanguage, label =>
      navigationRequest({ status: latestStatus.current!, label })
    )
  );
  const [kpiData, setKpiData] = useState<RtiData[]>();

  const handleStatusChange = (status: string): void => {
    setStatus(() => {
      latestStatus.current = status;
      return status;
    });
    const series = options.series![0] as SeriesVariablepieOptions;
    const kpiData = rtiData
      .find(r => r.status === status)
      ?.data.map(k => ({ ...k }));
    setOptions({ ...options, series: [{ ...series, data: kpiData! }] });
    setKpiData(kpiData!);
  };

  useEffect(() => {
    if (!rtiData) return;
    i18n.changeLanguage(currentLanguage);
    const defaultStatus =
      rtiData.find(rti => rti.data.some(kpi => kpi.y !== 0))?.status ||
      rtiData[0].status;
    handleStatusChange(defaultStatus);
  }, [rtiData]);

  const RefreshActionTemplate = (): JSX.Element => (
    <button
      className="btn btn-discreet-primary btn-lg btn-icon-start"
      onClick={() => {
        retryClick();
      }}
    >
      <i className="icon">refresh</i> {translate("ttdRti.retryBtn")}
    </button>
  );

  const actionsTemplate = <RefreshActionTemplate />;
  const content = isLoading ? (
    <Loading></Loading>
  ) : isError ? (
    <NoDataFiller
      title={translate("ttdRti.viewSelector.technicalIssue.title")}
      icon="error_outline"
      actionsTemplate={actionsTemplate}
      padding={false}
    >
      {translate("ttdRti.viewSelector.technicalIssue.message")}
    </NoDataFiller>
  ) : (
    <>
      {rtiData.length > 1 ? (
        <StatusNavTemplate
          statuses={rtiData.flatMap(r => r.status)}
          defaultStatus={status!}
          onStatusChange={handleStatusChange}
        />
      ) : (
        undefined
      )}

      {kpiData && kpiData.some(k => k.y !== 0) ? (
        <div className="d-flex">
          <KpiListTemplate
            kpiData={kpiData}
            navigationRequest={label =>
              navigationRequest({ status: status!, label })
            }
          />
          <div>
            <HighchartsReact
              highcharts={Highcharts}
              options={options}
              ref={chartComponentRef}
            />
          </div>
        </div>
      ) : (
        <div className="d-flex" style={{ justifyContent: "center" }}>
          <div className="empty-state py-5 py-64px">
            <h6 className="fs-14 fw-medium">{translate("ttdRti.noData")}</h6>
          </div>
        </div>
      )}
    </>
  );
  return (
    <WithCss styles={`${widgetStyles} ${fadingKeyframes}`}>
      <div className="d-flex flex-column bg-lvl1">{content}</div>
    </WithCss>
  );
};

widgetize("ttd-real-time-indicators", RTI, {
  rtiData: WidgetPropsMapping.asObject({
    description: "Indicates data todisplay",
  }),
  navigationRequest: WidgetPropsMapping.asEventEmitter("navigation-request", {
    description: "Event occuring when the widget requests a navigation",
  }),
  isLoading: WidgetPropsMapping.asObject({
    description: "Indicates if there is a load operation pending",
  }),
  isError: WidgetPropsMapping.asObject({
    description:
      "Indicates if there was an error when trying to fetch the data",
  }),
  retryClick: WidgetPropsMapping.asEventEmitter("retry-click", {
    description: "Event occurring when retry button is clicked",
  }),
});
