import { PointClickEventObject } from "highcharts";

const chartData: Highcharts.PointOptionsObject[] = [
  {
    name: "OK",
    y: 0,
    colorIndex: 0,
  },
  {
    name: "Warning",
    y: 0,
    colorIndex: 1,
  },
  {
    name: "Late",
    y: 0,
    colorIndex: 2,
  },
];

export const getOptions = (
  sVGElements: Highcharts.SVGElement[],
  currentLanguage: string,
  pointClickHandler: (name: string) => void
): Highcharts.Options => ({
  credits: { enabled: false },
  series: [
    {
      data: chartData,
      size: "100%",
      innerSize: "80%",
      showInLegend: true,
      dataLabels: {
        enabled: false,
      },
      type: (undefined as unknown) as "variablepie",
      point: {
        events: {
          click: (event: PointClickEventObject) => {
            pointClickHandler(event.point.name);
          },
        },
      },
    },
  ],
  chart: {
    margin: [0, 0, 0, 0],
    spacingTop: 0,
    spacingBottom: 0,
    spacingLeft: 0,
    spacingRight: 0,
    type: "pie",
    height: 175,
    width: 175,
    events: {
      render: (event: Event) => {
        const chart = (event.target as unknown) as Highcharts.Chart;
        if (!chart) return;
        sVGElements.pop()?.destroy();
        sVGElements.push(
          chart.renderer
            .text(
              `<div class="align-items-center d-flex flex-column" style="font-family:Inter"><span class="h3 text-primary">${chart.series
                .flatMap(s => s.data)
                .map(d => d.y ?? 0)
                .reduce((x, y) => x + y, 0)
                .toLocaleString(currentLanguage)}</span>
              <span class="fs-14 fw-medium text-primary" >Operations</span></div>`,
              50,
              75,
              true
            )
            .add()
        );
      },
    },
  },
  plotOptions: {
    pie: {
      cursor: "pointer",
    },
  },
  title: {
    text: undefined,
  },
  tooltip: {
    useHTML: true,
    style: {
      fontSize: "12px",
      fontFamily: "Inter",
    },
    headerFormat: "",
    pointFormat:
      '<div class="bg-lvl1 p-8px shadow"><div class="mb-8px">{point.name}</div>' +
      '<div style="display:flex; justify-content:flex-start; align-content:center;">' +
      '<span class="highcharts-tt-color-{point.colorIndex}">&#9632;&nbsp; </span>' +
      '<span style="font-weight:bold;">Count: &nbsp;{point.y} ({point.percentage:.1f}%)</span>' +
      "</div></div>",
    valueDecimals: 2,
    backgroundColor: undefined,
    borderWidth: 0,
  },
  legend: {
    enabled: false,
  },
  exporting: {
    buttons: {
      contextButton: {
        enabled: false,
      },
    },
  },
});
